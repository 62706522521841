import { mapActions } from "vuex";
import { JSONPath } from "jsonpath-plus";

export default {
  methods: {
    ...mapActions({
      findProfiles: "profiles/find",
    }),
    async validateProfiles(validateCase) {
      const requiredPaths = [
        "$.demand_profile", // *
        "$.generators[?(@.type==='RENEWABLE')][profile]", // *
        // "$.demand_response[*].operating_status.param.id", //
        // "$.markets[*].price_profiles", //
        // "$.markets[*].buy_limit_profiles", //
        // "$.markets[*].sell_limit_profiles", //
        // "$.projects[*].capacity_fade", //
        // "$.renewable_shifting.param.generator.profile", //
        // "$.renewable_shifting.param.generator.rt_profile", //
        // "$.demand_constraints.param.profiles", //
        // "$.generators[*].operating_status.param.id", //
        // "$.generators[*].base_profile.param.profiles", //
        // "$.generators[*].profile", // *
        // "$.generators[*].profile_fuel_price", //
        // "$.storage[*].operating_status.param.id", //
        // "$.storage[*].capacity_fade",
      ];
      const profiles = requiredPaths.reduce((arr, path) => {
        const result = JSONPath({ path, json: validateCase.input });
        if (Array.isArray(result)) arr.push({ path, ids: result.flat() });
        else arr.push({ path, ids: result });
        return arr;
      }, []);

      const profileIds = profiles.flatMap((v) => v.ids);

      const res = await this.findProfiles({
        query: { id: { $in: profileIds }, $select: ["id"] },
      });
      const resIds = res.map((v) => v.id);
      const missingProfiles = profileIds.filter((v) => !resIds.includes(v));

      let errors = [];
      if (missingProfiles.length) {
        errors = missingProfiles.map((id) => ({
          id,
          dataPath: this.buildPath(profiles.find((p) => p.ids.includes(id)).path, validateCase, id),
          message: `Profile ID ${id} not found`,
        }));
      }
      return errors;
    },
    buildPath(path, validateCase, id) {
      if (path.includes("generators")) {
        const generator = validateCase.input.generators
          .filter((v) => v.type === "RENEWABLE")
          .find((v) => v.profile.includes(id));
        return `/Generators/${generator.name}_(ID:_${generator.id})`;
      }
      if (path.includes("demand")) {
        return "/Demand";
      }
      return path;
    },
    populateProfiles() {
      const caseCopy = this.currentCase;
      // Demand Profile
      caseCopy.input.demand_profile = this.fillProfileArray(caseCopy.input.demand_profile);
      // Renewable Generators profiles
      const path = "$.generators[?(@.type==='RENEWABLE')]";
      JSONPath({ path, json: caseCopy.input }).forEach((g) => {
        caseCopy.input.generators.find((v) => v.id === g.id).profile = this.fillProfileArray(
          g.profile
        );
      });
      this.currentCase = caseCopy;
    },
    fillProfileArray(arr) {
      let arrayCopy = arr;
      if (this.num_years > arrayCopy.length) {
        const itemsToAppend = Array(this.num_years - arrayCopy.length).fill(arrayCopy[0]);
        arrayCopy = arrayCopy.concat(itemsToAppend);
      } else if (this.num_years < arrayCopy.length) {
        arrayCopy = arrayCopy.slice(0, this.num_years);
      }
      return arrayCopy;
    },
  },
};
